$('.burger').on('click', function() {
	$('.re-menu').addClass('__show');
});

$('.re-menu .close').on('click', function() {
	$('.re-menu').removeClass('__show');
});

$('.label-politic span').on('click', function() {
	$(this).toggleClass('__active');
});

$('.isn1 .right a.fb__btn, .resn .right a.fb__btn').on('click', function() {
	$('.feedback-form').addClass('__show');
});

$('.feedback-form, .feedback-form .close').on('click', function() {
	$('.feedback-form').removeClass('__show');
});

$('.feedback-form .close').on('click', function() {
	$('.feedback-form').removeClass('__show');
});

$('.feedback-form .block').on('click', function(e) {
	 e.stopPropagation();
});


$('.img-lists img').on('click', function() {
	 var ta = $(this).attr('src');

	 $('.prsn2 .img-full img').attr('src', ta);
});
